import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getReceipe } from "../../../services/receipesServices";
import { allIngredients } from "../../../services/ingredientsServices";
import ReceipeEditForm from "./ReceipeEditForm";

const ReceipeEdit = () => {
  let { id } = useParams();

  const { data: ingredinets, isLoading } = useQuery(
    "all-ingredinets",
    allIngredients
  );
  const { data: receipe, isLoading: getReceipeLoading } = useQuery(
    ["get-receipe", id],
    () => getReceipe(id)
  );

  if (getReceipeLoading || isLoading) {
    return "...is Loading";
  }
  return (
    <ReceipeEditForm
      receipe={receipe.data.receipe}
      ingredinets={ingredinets.data.products}
      id={id}
    />
  );
};

export default ReceipeEdit;
