import React, { useState } from "react";
import "../../styles/ingredients.css";
import { Badge, Button, Pagination } from "@mui/material";
import ReceipeCard from "./components/ReceipeCard";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReceipeModal from "./components/ReceipeModal";
import {
  listReceipes,
  getAllReceipes,
  getReceipesByStatus,
  getReceipesByName
} from "../../services/receipesServices";
import { useQuery } from "react-query";
import Filter from "../../components/Filter";

const Recipes = () => {
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const {
    data: receipes,
    isSuccess,
    isError,
    isLoading: isLoading_receipes
  } = useQuery(
    ["list-receipes", currentPage],
    () => listReceipes(currentPage),
    {
      keepPreviousData: true
    }
  );
  const { data: allReceipes, isLoading: isLoading_all_receipes } = useQuery(
    "all-receipes",
    getAllReceipes
  );
  const {
    data: receipesByStatus,
    isLoading: isLoading_receipes_by_status,
    refetch: refetchByStatus
  } = useQuery(
    ["get-receipe-status", status],
    () => getReceipesByStatus(status),
    {
      enabled: false
    }
  );

  const {
    data: receipesByName,
    isLoading: isLoading_receipes_by_name,
    refetch: refetchByName
  } = useQuery(["get-receipe-name", name], () => getReceipesByName(name), {
    enabled: false
  });
  if (isLoading_receipes || isLoading_all_receipes) {
    return "...is Loading";
  }
  return (
    <div>
      <div className="page-settings">
        <div className="page-infos">
          <h5 className="page-title">Receipes</h5>
          <Badge
            badgeContent={
              receipesByName?.data.totalResults
                ? receipesByName?.data.totalResults
                : receipesByStatus?.data.totalResults
                ? receipesByStatus?.data.totalResults
                : receipes?.data.totalResults
            }
            color="primary"
            max={999}
          >
            <DinnerDiningIcon color="action" />
          </Badge>
        </div>
        <div className="settings-buttons">
          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            className="add-ingredient-button"
            onClick={() => setShowModal(true)}
          >
            Add Receipe
          </Button>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            className="filter-button"
            onClick={() => setShowFilter(true)}
          >
            Filter
          </Button>
        </div>
      </div>
      <Pagination
        style={{ marginTop: 10, padding: "0px 30px" }}
        page={currentPage}
        count={
          receipesByName?.data.totalPages
            ? receipesByName?.data.totalPages
            : receipesByStatus?.data.totalPages
            ? receipesByStatus?.data.totalPages
            : receipes?.data.totalPages
        }
        variant="outlined"
        shape="rounded"
        hidePrevButton
        hideNextButton
        onChange={(event, value) => setCurrentPage(value)}
      />
      <div className="cards-container">
        {receipesByName?.data.receipes
          ? receipesByName?.data.receipes.map((el, i) => (
              <ReceipeCard key={i} receipe={el} />
            ))
          : receipesByStatus?.data.receipes
          ? receipesByStatus?.data.receipes.map((el, i) => (
              <ReceipeCard key={i} receipe={el} />
            ))
          : receipes?.data.receipes.map((el, i) => (
              <ReceipeCard key={i} receipe={el} />
            ))}
      </div>
      {showModal && (
        <ReceipeModal showModal={showModal} setShowModal={setShowModal} />
      )}
      {showFilter && (
        <Filter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          status={status}
          setStatus={setStatus}
          refetchByStatus={refetchByStatus}
          name={name}
          setName={setName}
          refetchByName={refetchByName}
          allElements={allReceipes.data.receipes}
        />
      )}
    </div>
  );
};

export default Recipes;
