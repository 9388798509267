import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState();
  const logOut = () => {
    localStorage.removeItem("health_check_token");
    setCurrentUser();
    navigate("/");
  };

  return (
    <GlobalContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        logOut
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
