import React, { useState, useEffect } from "react";
import {
  Modal,
  Grid,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  IconButton
} from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import useDrivePicker from "react-google-drive-picker";
import { useMutation, useQueryClient } from "react-query";
import { createIngredient } from "../../../services/ingredientsServices";
import AddNutritionalData from "../../../components/AddNutritionalData";

const IngredientModal = ({ showModal, setShowModal }) => {
  const [ingredientForm, setIngredientForm] = useState({
    text: "",
    description: "",
    type: "",
    nutritionalData: {},
    createdBy: "62f614ef1e5b573389edd93d",
    images: []
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIngredientForm({ ...ingredientForm, [name]: value });
  };
  const [openPicker, authResponse] = useDrivePicker();
  const handleClose = () => {
    setShowModal(false);
  };
  //UPLOAD IMAGE IN GOOGLE DRIVE
  const SCOPE = "https://www.googleapis.com/auth/drive";
  const discoveryUrl =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  const CLIENT_ID =
    "1087047118044-nrapun4q76871bnb2mbu7s07du542mk8.apps.googleusercontent.com";
  const API_KEY = "AIzaSyARITWFDPBNjDxqpgLcct1ebCPD-Vafkmg";

  useEffect(() => {
    var script = document.createElement("script");
    script.onload = handleClientLoad;
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
  }, []);

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };

  const [token, setToken] = useState("");
  const initClient = () => {
    try {
      window.gapi.auth2
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          plugin_name: "chat",
          scope: SCOPE,
          discoveryDocs: [discoveryUrl]
        })
        .then(() => {
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn?.listen(updateSigninStatus);
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn?.get()
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  const updateSigninStatus = () => {
    setSigninStatus();
  };
  const setSigninStatus = () => {
    var user = window.gapi.auth2.getAuthInstance().currentUser.get();
    const scopes = user.getGrantedScopes()?.split(" ");
    if (scopes && !scopes.includes("https://www.googleapis.com/auth/drive")) {
      user
        .grant({
          scope: "https://www.googleapis.com/auth/drive"
        })
        .then(() => {
          console.log(user.getGrantedScopes()?.split(" "));
        });
    } else (console.log("hello"))
    setToken(window.gapi.auth.getToken()?.access_token);

    var isAuthorized = user.hasGrantedScopes();
    if (isAuthorized) {
      setToken(window.gapi.auth.getToken().access_token);
    }
  };
  const handleOpenPicker = () => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_IMAGES",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          setIngredientForm((prev) => ({
            ...prev,
            images: [...prev.images, url]
          }));
        });
        console.log(data);
      }
    });
  };
  const handleDeleteImage = (i) => {
    let arr = ingredientForm.images;
    setIngredientForm({
      ...ingredientForm,
      images: arr.filter((el) => ingredientForm.images.indexOf(el) !== i)
    });
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createIngredient, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-ingredinets");
      handleClose();
    }
  });
  const handleSubmitIngredient = () => {
    const errorsTemp = {};
    if (!ingredientForm.text) {
      errorsTemp.text = "*This field is required";
    }
    if (!ingredientForm.description) {
      errorsTemp.description = "*This field is required";
    }
    if (!ingredientForm.type) {
      errorsTemp.type = "*This field is required";
    }
    if (Object.keys(ingredientForm.nutritionalData).length === 0) {
      errorsTemp.nutritionalData = "*Please fill in the nutritional data";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(ingredientForm);
    }
  };
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll", zIndex: 4 }}
    >
      <Grid
        container
        item
        xs={10}
        direction="column"
        className="add-ingredient-modal-wrapper"
      >
        <div className="add-ingredient-modal-inputs">
          <Grid
            container
            item
            xs={6}
            direction="column"
            className="modal-left-side"
          >
            <div>
              <h5>Name</h5>
              <TextField
                id="outlined-basic"
                name="text"
                variant="outlined"
                style={{ width: "100%" }}
                value={ingredientForm.text}
                onChange={handleChange}
              />
              {errors.text && <p className="error-msg">{errors.text}</p>}
            </div>
            <div>
              <h5>Description</h5>
              <TextField
                id="outlined-multiline-static"
                name="description"
                variant="outlined"
                value={ingredientForm.description}
                multiline
                rows={5}
                style={{ width: "100%" }}
                onChange={handleChange}
              />
              {errors.description && (
                <p className="error-msg">{errors.description}</p>
              )}
            </div>
          </Grid>

          <Grid
            container
            item
            xs={6}
            direction="column"
            className="modal-right-side"
          >
            <div>
              <h5>Type</h5>
              <FormControl sx={{ m: 1, minWidth: "100%",margin:0 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  value={ingredientForm.type}
                  onChange={handleChange}
                >
                  <MenuItem value="INT">Interieur</MenuItem>
                  <MenuItem value="EXT">Exterieur</MenuItem>
                  <MenuItem value="USER">User</MenuItem>
                </Select>
              </FormControl>
              {errors.type && <p className="error-msg">{errors.type}</p>}
            </div>
            <div>
              <h5>Image</h5>
              <div className="images-wrapper">
                {ingredientForm.images.map((el, i) => (
                  <div key={i} className="image-container">
                    <div className="image-box">
                      <img alt="" src={el} />
                    </div>
                    <IconButton
                      className="delete-image"
                      onClick={() => handleDeleteImage(i)}
                    >
                      <CancelTwoToneIcon />
                    </IconButton>
                  </div>
                ))}
                <div className="modal-add-image-container">
                  <div className="inner-add-image-container">
                    <IconButton onClick={() => handleOpenPicker()}>
                      <AddPhotoAlternateIcon />
                    </IconButton>
                  </div>

                  <p>Ajouter une image</p>
                </div>
              </div>
            </div>
          </Grid>
        </div>
        <AddNutritionalData
          ingredientForm={ingredientForm}
          setIngredientForm={setIngredientForm}
          errors={errors}
        />
        <div className="add-ingredient-modal-buttons">
          <Button
            variant="contained"
            className="add-button"
            onClick={handleSubmitIngredient}
          >
            Add
          </Button>
          <Button
            variant="contained"
            className="cancel-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default IngredientModal;
