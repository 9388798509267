import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import Login from "./pages/login/Login";
import { GlobalContext } from "./providers/Global";
import NewRoutes from "./routing/newRoutes";
import PrivateRoute from "./routing/PrivateRoute";
import { adminAccount } from "./services/authServices";

function Main() {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(GlobalContext);
  const currentToken = localStorage.getItem("health_check_token");
  const { data: adminAccountData, isLoading: adminAccountLoading } = useQuery(
    "admin-account",
    adminAccount,
    { enabled: !currentUser && currentToken ? true : false }
  );
  useEffect(() => {
    if (adminAccountData?.success) {
      setCurrentUser(adminAccountData?.data.admin);
      navigate("/dashboard/ingredients");
    }
  }, [adminAccountData?.success]);
  if (adminAccountLoading) {
    return "...is loading";
  }
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            currentToken ? <Navigate to="/dashboard/ingredients" /> : <Login />
          }
        />

        <Route element={<PrivateRoute currentToken={currentToken} />}>
          <Route path="dashboard/*" element={<NewRoutes />} />
        </Route>
      </Routes>
    </>
  );
}

export default Main;
