import React, { useState } from "react";
import "../../styles/tools.css";
import { Badge, Button, Pagination } from "@mui/material";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useQuery } from "react-query";
import { listTools } from "../../services/toolsServices";
import ToolCard from "./components/ToolCard";
import ToolModal from "./components/ToolModal";

const Tools = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: tools,
    isSuccess,
    isError,
    isLoading: isLoading_tools
  } = useQuery(["list-tools", currentPage], () => listTools(currentPage), {
    keepPreviousData: true
  });
  if (isLoading_tools) {
    return "...is Loading";
  }
  return (
    <div>
      <div className="page-settings">
        <div className="page-infos">
          <h5 className="page-title">Tools</h5>
          <Badge
            badgeContent={tools?.data.totalResults}
            color="primary"
            max={999}
          >
            <RestaurantIcon color="action" />
          </Badge>
        </div>
        <div className="settings-buttons">
          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            className="add-ingredient-button"
            onClick={() => setShowModal(true)}
          >
            Add Tool
          </Button>
        </div>
      </div>
      <Pagination
        style={{ marginTop: 10, padding: "0px 30px" }}
        page={currentPage}
        count={tools?.data.totalPages}
        variant="outlined"
        shape="rounded"
        hidePrevButton
        hideNextButton
        onChange={(event, value) => setCurrentPage(value)}
      />
      <div className="cards-container">
        {tools?.data.tools.map((el, i) => (
          <ToolCard key={i} tool={el} />
        ))}
      </div>
      {showModal && (
        <ToolModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default Tools;
