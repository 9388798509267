import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Typography
} from "@mui/material";

const StepCard = ({ step, ingredinets }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ borderBottom: "1px solid #66666626" }}
      >
        <Typography
          sx={{
            width: "30%",
            flexShrink: 0,
            color: "#ef2531",
            fontWeight: 700
          }}
        >
          Step number <span>{step.number}</span>
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
            width: "50%",
            flexShrink: 0,
            color: "#565b60",
            fontWeight: 700
          }}
        >
          {step.name}
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
            display: "flex",
            alignItems: "center",
            gap: "5px"
          }}
        >
          <AccessTimeIcon style={{ color: "#ef2531" }} />
          {step.time} Min
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container item xs={5} direction="column">
            <Typography
              sx={{
                color: "#079a91",
                fontWeight: 700
              }}
            >
              Description:
            </Typography>
            <Typography
              sx={{
                marginTop: "15px"
              }}
            >
              {step.description}
            </Typography>
          </Grid>
          {step.products.products_internal.length !== 0 && (
            <Grid container item xs={5} direction="column">
              <Typography
                sx={{
                  color: "#079a91",
                  fontWeight: 700
                }}
              >
                Internal Ingredients
              </Typography>
              <div className="chips-container">
                {step.products.products_internal.map((el, i) => (
                  <Chip
                    key={i}
                    label={
                      ingredinets.find((ingredinet) => ingredinet._id === el)
                        ?.text
                    }
                  />
                ))}
              </div>
            </Grid>
          )}
        </div>
        {step.media.images.length !== 0 && (
          <div>
            <Typography
              sx={{
                color: "#079a91",
                fontWeight: 700,
                margin: "15px 0px"
              }}
            >
              Images:
            </Typography>
            <div className="images-wrapper">
              {step.media.images.map((el, i) => (
                <div key={i} className="image-container">
                  <div className="image-box">
                    <img alt="" src={el} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {step.media.videos.length !== 0 && (
          <div>
            <Typography
              sx={{
                color: "#079a91",
                fontWeight: 700,
                margin: "15px 0px"
              }}
            >
              Videos:
            </Typography>
            <div className="images-wrapper">
              {step.media.videos.map((el, i) => (
                <div key={i} className="video-container">
                  <div className="video-box">
                    <video alt="" src={el} controls />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default StepCard;
