import React, { useState, useEffect } from "react";
import { Button, IconButton, TextField, Alert } from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import useDrivePicker from "react-google-drive-picker";
import _ from "lodash";
import { updateTool } from "../../../services/toolsServices";

const ToolEditForm = ({ tool, id }) => {
  const [toolForm, setToolForm] = useState(tool);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setToolForm({ ...toolForm, [name]: value });
  };
  //UPLOAD IMAGE IN GOOGLE DRIVE
  const SCOPE = "https://www.googleapis.com/auth/drive";
  const discoveryUrl =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  const CLIENT_ID =
    "1087047118044-nrapun4q76871bnb2mbu7s07du542mk8.apps.googleusercontent.com";
  const API_KEY = "AIzaSyARITWFDPBNjDxqpgLcct1ebCPD-Vafkmg";

  useEffect(() => {
    var script = document.createElement("script");
    script.onload = handleClientLoad;
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
  }, []);

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };

  const [token, setToken] = useState("");
  const initClient = () => {
    try {
      window.gapi.auth2
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          plugin_name: "chat",
          scope: SCOPE,
          discoveryDocs: [discoveryUrl]
        })
        .then(() => {
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn?.listen(updateSigninStatus);
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn?.get()
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  const updateSigninStatus = () => {
    setSigninStatus();
  };
  const setSigninStatus = () => {
    var user = window.gapi.auth2.getAuthInstance().currentUser.get();
    const scopes = user.getGrantedScopes()?.split(" ");
    if (!scopes.includes("https://www.googleapis.com/auth/drive")) {
      user
        .grant({
          scope: "https://www.googleapis.com/auth/drive"
        })
        .then(() => {
          console.log(user.getGrantedScopes()?.split(" "));
        });
    }
    setToken(window.gapi.auth.getToken()?.access_token);

    var isAuthorized = user.hasGrantedScopes();
    if (isAuthorized) {
      setToken(window.gapi.auth.getToken().access_token);
    }
  };
  const handleOpenPicker = () => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_IMAGES",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          setToolForm((prev) => ({
            ...prev,
            image: url
          }));
        });
        console.log(data);
      }
    });
  };
  const handleDeleteImage = () => {
    setToolForm({
      ...toolForm,
      image: ""
    });
  };
  const queryClient = useQueryClient();
  const {
    mutate,
    data: updatedTool,
    isSuccess,
    isError
  } = useMutation(updateTool, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-tools");
    }
  });
  const handleEditTool = () => {
    const errorsTemp = {};
    if (!toolForm.name) {
      errorsTemp.name = "*This field is required";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      const dataToSend = _.omit(toolForm, ["_id", "createdAt"]);
      mutate({ id: id, data: dataToSend });
    }
  };
  useEffect(() => {
    if (updatedTool?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updatedTool?.success]);
  return (
    <div>
      <div className="show-more-container">
        <div className="show-more-elements-container">
          <div className="show-more-element">
            <h5>Name</h5>
            <TextField
              id="outlined-basic"
              name="name"
              variant="outlined"
              style={{ width: "50%" }}
              value={toolForm?.name}
              onChange={handleChange}
            />
            {errors.name && <p className="error-msg">{errors.name}</p>}
          </div>

          <div className="show-more-element">
            <h5>Image</h5>
            <div className="images-wrapper">
              <div className="images-wrapper">
                {toolForm.image ? (
                  <div className="image-container">
                    <div className="image-box">
                      <img alt="" src={toolForm.image} />
                    </div>
                    <IconButton
                      className="delete-image"
                      onClick={() => handleDeleteImage()}
                    >
                      <CancelTwoToneIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div className="modal-add-image-container">
                    <div className="inner-add-image-container">
                      <IconButton onClick={() => handleOpenPicker()}>
                        <AddPhotoAlternateIcon />
                      </IconButton>
                    </div>

                    <p>Ajouter une image</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="show-more-button-container">
            <Button
              variant="contained"
              className="add-button"
              onClick={handleEditTool}
            >
              Save
            </Button>
            <Link to={`/dashboard/tools/show-more/${toolForm?._id}`}>
              <Button variant="contained" className="cancel-button">
                Cancel
              </Button>
            </Link>
          </div>
          {showAlert && (
            <>
              <Alert
                severity="success"
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                Tool is updated!
              </Alert>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToolEditForm;
