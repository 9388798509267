import api from "../config/api";

// create ingredient
export const createIngredient = (data) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "post",
    url: `/api/products`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get ingredinets with pagination
export const listIngredients = (currentPage) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/products/?page=${currentPage}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// get an ingredient
export const getIngredient = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/products/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// confirm an ingredient
export const confirmIngredient = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "put",
    url: `/api/products/confirm/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// decline an ingredient
export const declineIngredient = ({ id, data }) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "put",
    url: `/api/products/decline/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// delete an ingredient
export const deleteIngredient = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "delete",
    url: `/api/products/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// update an ingredient
export const updateIngredient = ({ id, data }) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "put",
    url: `/api/products/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// list all ingredients
export const allIngredients = () => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/products`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// filter ingredinets by status
export const ingredientsByStatus = (status) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/products/?status=${status}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// filter ingredinets by name
export const ingredientsByName = (name) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/products/?query=${name}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
