import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import IngredientDefaultImage from "../../../assets/No_image_available.svg.png";
import { Button } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useQuery } from "react-query";
import {
  getIngredient,
  confirmIngredient,
  declineIngredient
} from "../../../services/ingredientsServices";
import UpdateStatusModal from "../../../components/UpdateStatusModal";

const IngredientShowMore = () => {
  let { id } = useParams();
  const [showStatusModal, setShowStatusModal] = useState({
    open: false,
    id: null,
    status: ""
  });
  const { data: ingredientData, isLoading: getIngredientLoading } = useQuery(
    ["get-ingredient", id],
    () => getIngredient(id)
  );

  if (getIngredientLoading) {
    return "...is loading";
  }

  return (
    <div>
      <div className="show-more-container">
        <div className="show-more-elements-container">
          <div className="show-more-element">
            <h5>Name</h5>
            <h6>{ingredientData?.data.product.text}</h6>
          </div>
          <div className="show-more-element">
            <h5>description</h5>
            <h6>{ingredientData?.data.product.description}</h6>
          </div>
          <div className="show-more-element">
            <h5>Nutritional Data</h5>
            <div className="nutritional-data-container">
              {Object.entries(ingredientData?.data.product.nutritionalData).map(
                (elData, i) => (
                  <div className="nutritional-data-element" key={i}>
                    <p className="nutritional-data-name">{elData[0]}</p>
                    <p className="nutritional-data-value">{elData[1]}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="show-more-element">
            <h5>Status</h5>
            <h6>{ingredientData?.data.product.status}</h6>
          </div>
          <div className="show-more-element">
            <h5>Type</h5>
            <h6>{ingredientData?.data.product.type}</h6>
          </div>
          <div className="show-more-element">
            <h5>Images</h5>
            <div className="images-wrapper">
              {ingredientData?.data.product.images.length !== 0 ? (
                ingredientData?.data.product.images.map((image, i) => (
                  <div className="image-container" key={i}>
                    <div className="image-box">
                      <img alt="" src={image} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="image-container">
                  <div className="image-box">
                    <img alt="" src={IngredientDefaultImage} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="show-more-button-container">
            <div className="show-more-element">
              <h5>Status</h5>
              {ingredientData?.data.product.status === "PENDING" ? (
                <>
                  <div className="update-status">
                    <h6>{ingredientData?.data.product.status}</h6>
                    <Button
                      variant="outlined"
                      className="confirm-button"
                      startIcon={<ThumbUpAltIcon />}
                      onClick={() => {
                        setShowStatusModal({
                          open: true,
                          id: id,
                          status: "CONFIRMED"
                        });
                      }}
                    >
                      CONFIRM
                    </Button>
                    <Button
                      variant="contained"
                      className="decline-button"
                      endIcon={<ThumbDownAltIcon />}
                      onClick={() =>
                        setShowStatusModal({
                          open: true,
                          id: id,
                          status: "DECLINED"
                        })
                      }
                    >
                      DECLINE
                    </Button>
                  </div>
                </>
              ) : ingredientData?.data.product.status === "DECLINED" ? (
                <>
                  <h6>{ingredientData?.data.product.status}</h6>
                  <h3 className="decline-reason">
                    {ingredientData?.data.product.declineReason}
                  </h3>
                </>
              ) : (
                <h6>{ingredientData?.data.product.status}</h6>
              )}
            </div>
            {ingredientData?.data.product.status !== "CONFIRMED" && (
              <Link to={`/dashboard/ingredients/edit/${id}`}>
                <Button
                  variant="contained"
                  className="edit-button"
                  style={{ marginBottom: 10 }}
                >
                  Edit
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {showStatusModal.open && showStatusModal.status === "CONFIRMED" && (
        <UpdateStatusModal
          showStatusModal={showStatusModal}
          setShowStatusModal={setShowStatusModal}
          item="Ingredient"
          queryFnt={confirmIngredient}
          queryKey="get-ingredient"
        />
      )}
      {showStatusModal.open && showStatusModal.status === "DECLINED" && (
        <UpdateStatusModal
          showStatusModal={showStatusModal}
          setShowStatusModal={setShowStatusModal}
          item="Ingredient"
          queryFnt={declineIngredient}
          queryKey="get-ingredient"
        />
      )}
    </div>
  );
};

export default IngredientShowMore;
