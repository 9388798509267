import React from "react";
import "../styles/reusableComponents.css";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Autocomplete
} from "@mui/material";

const Filter = ({
  showFilter,
  setShowFilter,
  status,
  setStatus,
  refetchByStatus,
  name,
  setName,
  refetchByName,
  allElements
}) => {
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleResetStatus = () => {
    setStatus("");
  };

  //   const handleChangeName = (e) => {
  //     setName(e.target.value);
  //   };
  const handleResetName = () => {
    setName("");
  };

  return (
    <>
      <div className="filter-wrapper">
        <div className="filter-backdrop" onClick={() => setShowFilter(false)} />
        <div
          className={showFilter ? "filter-content active" : "filter-content"}
        >
          <div className="filter-content-header">
            <IconButton onClick={() => setShowFilter(false)}>
              <ArrowCircleLeftIcon
                style={{ width: 34, height: 34, color: "#f9b12a" }}
              />
            </IconButton>
            <h1>Filters</h1>
          </div>
          <div className="filter-inputs">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Filter by Status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Choose a status</Typography>
                <FormControl style={{ width: "100%", marginTop: 20 }}>
                  <Select
                    name="status"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value="PENDING">Pending</MenuItem>
                    <MenuItem value="CONFIRMED">Confirmed</MenuItem>
                    <MenuItem value="DECLINED">Declined</MenuItem>
                  </Select>
                </FormControl>
              </AccordionDetails>
              <div className="filter-buttons-container">
                <Button
                  variant="outlined"
                  className="apply-filter-button"
                  onClick={refetchByStatus}
                >
                  Apply filter
                </Button>
                <Button
                  variant="outlined"
                  className="reset-button"
                  onClick={handleResetStatus}
                >
                  Reset
                </Button>
              </div>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Filter by Name</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  options={allElements.map((option) => option.text)}
                  inputValue={name}
                  onInputChange={(event, newInputValue) => {
                    setName(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Enter a name" />
                  )}
                />
              </AccordionDetails>
              <div className="filter-buttons-container">
                <Button
                  variant="outlined"
                  className="apply-filter-button"
                  onClick={refetchByName}
                >
                  Apply filter
                </Button>
                <Button
                  variant="outlined"
                  className="reset-button"
                  onClick={handleResetName}
                >
                  Reset
                </Button>
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
