import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Main from "./Main";
import { GlobalProvider } from "./providers/Global";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // staleTime: 30000,
      retry: false
    }
  }
});
export default function App() {
  return (
    <Router>
      <GlobalProvider>
        <QueryClientProvider client={queryClient}>
          <Main />
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </GlobalProvider>
    </Router>
  );
}
