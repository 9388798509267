import React, { useState } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import IngredientDefaultImage from "../../../assets/No_image_available.svg.png";
import DeleteModal from "../../../components/DeleteModal";
import { Link } from "react-router-dom";
import { deleteTool } from "../../../services/toolsServices";
import { convertDate } from "../../../utils/convertDate";

const ToolCard = ({ tool }) => {
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  return (
    <div className="tool-card-container">
      <div className="tool-card-image-container">
        <img alt="" src={tool.image ? tool.image : IngredientDefaultImage} />
      </div>
      <Button variant="contained" className="tool-card-name">
        {tool.name}
      </Button>
      <div className="tool-card-icons">
        <p className="tool-card-date">{convertDate(tool.createdAt)}</p>
        <div>
          <Tooltip title="More infos">
            <Link to={`/dashboard/tools/show-more/${tool._id}`}>
              <IconButton>
                <InfoIcon style={{ color: "#079a91" }} />
              </IconButton>
            </Link>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              onClick={() =>
                setShowDeleteModal({
                  open: true,
                  id: tool._id
                })
              }
            >
              <DeleteIcon style={{ color: "#ef2531" }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          item="Tool"
          queryFnt={deleteTool}
          queryKey="list-tools"
        />
      )}
    </div>
  );
};

export default ToolCard;
