import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import IngredientDefaultImage from "../../../assets/No_image_available.svg.png";
import { Button, Chip } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useQuery } from "react-query";
import {
  confirmReceipe,
  declineReceipe,
  getReceipe
} from "../../../services/receipesServices";
import UpdateStatusModal from "../../../components/UpdateStatusModal";
import { allIngredients } from "../../../services/ingredientsServices";
import StepCard from "./StepCard";

const ReceipeShowMore = () => {
  let { id } = useParams();
  const [showStatusModal, setShowStatusModal] = useState({
    open: false,
    id: null,
    status: ""
  });
  const { data: receipetData, isLoading: getReceipeLoading } = useQuery(
    ["get-receipe", id],
    () => getReceipe(id)
  );
  const { data: ingredinets, isLoading } = useQuery(
    "all-ingredinets",
    allIngredients
  );
  if (getReceipeLoading || isLoading) {
    return "...is loading";
  }
  return (
    <div>
      <div className="show-more-container">
        <div className="show-more-elements-container">
          <div className="show-more-element">
            <h5>Name</h5>
            <h6>{receipetData?.data.receipe.text}</h6>
          </div>
          <div className="show-more-element">
            <h5>Description</h5>
            <h6>{receipetData?.data.receipe.description}</h6>
          </div>
          <div className="show-more-element">
            <h5>Nutritional Data</h5>
            <div className="nutritional-data-container">
              {Object.entries(receipetData?.data.receipe.nutritionalData).map(
                (elData, i) => (
                  <div className="nutritional-data-element" key={i}>
                    <p className="nutritional-data-name">{elData[0]}</p>
                    <p className="nutritional-data-value">{elData[1]}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="show-more-element">
            <h5>Difficulty</h5>
            <h6>{receipetData?.data.receipe.difficulty}</h6>
          </div>
          <div className="show-more-element">
            <h5>Type</h5>
            <h6>{receipetData?.data.receipe.type}</h6>
          </div>
          <div className="show-more-element">
            <h5>Persons</h5>
            <h6>
              {receipetData?.data.receipe.persons} <span>Person(s)</span>
            </h6>
          </div>
          <div className="show-more-element">
            <h5>Instructions</h5>
            <h6>{receipetData?.data.receipe.instructions}</h6>
          </div>
          <div className="show-more-element">
            <h5>Images</h5>
            <div className="images-wrapper">
              {receipetData?.data.receipe.media.images.length !== 0 ? (
                receipetData?.data.receipe.media.images.map((image, i) => (
                  <div className="image-container" key={i}>
                    <div className="image-box">
                      <img alt="" src={image} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="image-container">
                  <div className="image-box">
                    <img alt="" src={IngredientDefaultImage} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="show-more-element">
            <h5>Videos</h5>
            <div className="images-wrapper">
              {receipetData?.data.receipe.media.videos.length !== 0 ? (
                receipetData?.data.receipe.media.videos.map((video, i) => (
                  <div key={i} className="video-container">
                    <div className="video-box">
                      <video alt="" src={video} controls />
                    </div>
                  </div>
                ))
              ) : (
                <div className="image-container">
                  <div className="image-box">
                    <img alt="" src={IngredientDefaultImage} />
                  </div>
                </div>
              )}
            </div>
          </div>

          {receipetData?.data.receipe.steps.length !== 0 && (
            <>
              <div className="show-more-element">
                <h5>
                  Steps <span>({receipetData?.data.receipe.steps.length})</span>
                </h5>
                {receipetData?.data.receipe.steps.map((step, i) => (
                  <StepCard
                    key={i}
                    step={step}
                    ingredinets={ingredinets.data.products}
                  />
                ))}
              </div>
            </>
          )}

          <div className="show-more-element">
            <h5>Products</h5>

            {receipetData?.data.receipe.products.products_internal.length !==
              0 && (
              <>
                <h6>Internal Products</h6>
                <div className="chips-container">
                  {receipetData?.data.receipe.products.products_internal.map(
                    (inProduct, i) => (
                      <Chip
                        key={i}
                        label={
                          ingredinets.data.products.find(
                            (ingredinet) => ingredinet._id === inProduct
                          )?.text
                        }
                      />
                    )
                  )}
                </div>
              </>
            )}

            {receipetData?.data.receipe.products.products_external.length !==
              0 && (
              <>
                <h6>External Products</h6>
                <ul>
                  {receipetData?.data.receipe.products.products_external.map(
                    (exProduct, i) => (
                      <li key={i}>{exProduct}</li>
                    )
                  )}
                </ul>
              </>
            )}
          </div>
          <div className="show-more-button-container">
            <div className="show-more-element">
              <h5>Status</h5>
              {receipetData?.data.receipe.status === "PENDING" ? (
                <>
                  <div className="update-status">
                    <h6>{receipetData?.data.receipe.status}</h6>
                    <Button
                      variant="outlined"
                      className="confirm-button"
                      startIcon={<ThumbUpAltIcon />}
                      onClick={() => {
                        setShowStatusModal({
                          open: true,
                          id: id,
                          status: "CONFIRMED"
                        });
                      }}
                    >
                      CONFIRM
                    </Button>
                    <Button
                      variant="contained"
                      className="decline-button"
                      endIcon={<ThumbDownAltIcon />}
                      onClick={() =>
                        setShowStatusModal({
                          open: true,
                          id: id,
                          status: "DECLINED"
                        })
                      }
                    >
                      DECLINE
                    </Button>
                  </div>
                </>
              ) : receipetData?.data.receipe.status === "DECLINED" ? (
                <>
                  <h6>{receipetData?.data.receipe.status}</h6>
                  <h3 className="decline-reason">
                    {receipetData?.data.receipe.declineReason}
                  </h3>
                </>
              ) : (
                <h6>{receipetData?.data.receipe.status}</h6>
              )}
            </div>
            {receipetData?.data.receipe.status !== "CONFIRMED" && (
              <Link to={`/dashboard/receipes/edit/${id}`}>
                <Button
                  variant="contained"
                  className="edit-button"
                  style={{ marginBottom: 10 }}
                >
                  Edit
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {showStatusModal.open && showStatusModal.status === "CONFIRMED" && (
        <UpdateStatusModal
          showStatusModal={showStatusModal}
          setShowStatusModal={setShowStatusModal}
          item="Receipe"
          queryFnt={confirmReceipe}
          queryKey="get-receipe"
        />
      )}
      {showStatusModal.open && showStatusModal.status === "DECLINED" && (
        <UpdateStatusModal
          showStatusModal={showStatusModal}
          setShowStatusModal={setShowStatusModal}
          item="Receipe"
          queryFnt={declineReceipe}
          queryKey="get-receipe"
        />
      )}
    </div>
  );
};

export default ReceipeShowMore;
