import React, { useState } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Box
} from "@mui/material";

const AddNutritionalData = ({ ingredientForm, setIngredientForm, errors }) => {
  const [data, setData] = useState([
    { name: "Energy" },
    { name: "Glucide" },
    { name: "Polyols" },
    { name: "Amidon" },
    { name: "Proteines" },
    { name: "Dietary fiber" },
    { name: "Salt" },
    { name: "Vitamins" },
    { name: "Mineral salts" },
    { name: "Fat" }
  ]);
  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let dataTemp = data.map((el) =>
      el.name === name ? { ...el, isChecked: checked } : el
    );
    dataTemp.map((el) => {
      if (
        el.isChecked === true &&
        Object.keys(ingredientForm.nutritionalData).includes(el.name) === false
      ) {
        setIngredientForm((prev) => ({
          ...prev,
          nutritionalData: {
            ...prev.nutritionalData,
            [el.name]: 0
          }
        }));
      } else if (
        el.isChecked === false &&
        Object.keys(ingredientForm.nutritionalData).includes(el.name) === true
      ) {
        const newItems = ingredientForm.nutritionalData;
        delete newItems[el.name];
        setIngredientForm((prev) => ({
          ...prev,
          nutritionalData: newItems
        }));
      }
    });

    setData(dataTemp);
  };

  const addNutritionalDataValue = (e) => {
    setIngredientForm((prev) => ({
      ...prev,
      nutritionalData: {
        ...prev.nutritionalData,
        [e.target.name]: e.target.value
      }
    }));
  };
  return (
    <div>
      <h5 className="nutirtional-data-title">Nutritional Data</h5>
      <div>
        <FormGroup style={{ flexDirection: "row" }}>
          {data.map((el, i) => (
            <div style={{ width: 150 }} key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={el?.isChecked || false}
                    name={el.name}
                    onChange={handleCheck}
                  />
                }
                label={el.name}
                style={{ marginBottom: 10 }}
              />
              {el.isChecked && (
                <TextField
                  id="outlined-multiline-static"
                  className="nutirtional-data-input"
                  name={el.name}
                  value={ingredientForm.nutritionalData[el.name]}
                  variant="outlined"
                  style={{ width: "80%" }}
                  onChange={addNutritionalDataValue}
                  defaultValue={0}
                />
              )}
            </div>
          ))}
        </FormGroup>
      </div>

      {errors.nutritionalData && (
        <p className="error-msg">{errors.nutritionalData}</p>
      )}
    </div>
  );
};

export default AddNutritionalData;
