import { Button, Grid, Modal } from "@mui/material";
import React from "react";
import "../styles/reusableComponents.css";
import { useMutation, useQueryClient } from "react-query";

const DeleteModal = ({
  showDeleteModal,
  setShowDeleteModal,
  item,
  queryFnt,
  queryKey
}) => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError, isLoading } = useMutation(queryFnt, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      handleClose();
    }
  });
  const handleDelete = () => {
    mutate(showDeleteModal.id);
  };
  const handleClose = () => {
    setShowDeleteModal({
      open: false,
      id: null
    });
  };
  return (
    <Modal open={showDeleteModal.open} onClose={handleClose}>
      <Grid
        container
        item
        xs={5}
        md={5}
        lg={4}
        direction="column"
        className="delete-modal"
      >
        <h1 className="delete-modal-title">Delete {item}</h1>
        <h3 className="delete-modal-content">
          Do you really want to delete this item ?
        </h3>
        <div className="delete-modal-buttons">
          <Button className="confirm-delete-button" onClick={handleDelete}>
            Delete
          </Button>
          <Button className="cancel-delete-button" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default DeleteModal;
