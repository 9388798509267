import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import IngredientDefaultImage from "../../../assets/No_image_available.svg.png";
import { Button } from "@mui/material";
import { useQuery } from "react-query";
import { getTool } from "../../../services/toolsServices";

const ToolShowMore = () => {
  let { id } = useParams();
  const { data: toolData, isLoading: getToolLoading } = useQuery(
    ["get-tool", id],
    () => getTool(id)
  );

  if (getToolLoading) {
    return "...is loading";
  }

  return (
    <div>
      <div className="show-more-container">
        <div className="show-more-elements-container">
          <div className="show-more-element">
            <h5>Name</h5>
            <h6>{toolData?.data.tool.name}</h6>
          </div>
          <div className="show-more-element">
            <h5>Image</h5>
            <div className="images-wrapper">
              {toolData?.data.tool.image ? (
                <div className="tool-image-container">
                  <div className="tool-image-box">
                    <img alt="" src={toolData.data.tool.image} />
                  </div>
                </div>
              ) : (
                <div className="tool-image-container">
                  <div className="tool-image-box">
                    <img alt="" src={IngredientDefaultImage} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="tool-show-more-button-container">
            <Link to={`/dashboard/tools/edit/${id}`}>
              <Button
                variant="contained"
                className="edit-button"
                style={{ marginBottom: 10 }}
              >
                Edit
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolShowMore;
