import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "../layout/NavBar";
import ToolEdit from "../pages/food_tools/components/ToolEdit";
import ToolShowMore from "../pages/food_tools/components/ToolShowMore";
import Tools from "../pages/food_tools/Tools";
import IngredientEdit from "../pages/ingredients/components/IngredientEdit";
import IngredientShowMore from "../pages/ingredients/components/IngredientShowMore";
import Ingredients from "../pages/ingredients/Ingredients";
import ReceipeEdit from "../pages/receipes/components/ReceipeEdit";
import ReceipeShowMore from "../pages/receipes/components/ReceipeShowMore";
import Recipes from "../pages/receipes/Receipes";

const NewRoutes = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/ingredients" element={<Ingredients />} />
        <Route
          path="/ingredients/show-more/:id"
          element={<IngredientShowMore />}
        />
        <Route path="/ingredients/edit/:id" element={<IngredientEdit />} />
        <Route path="/receipes" element={<Recipes />} />
        <Route path="/receipes/show-more/:id" element={<ReceipeShowMore />} />
        <Route path="/receipes/edit/:id" element={<ReceipeEdit />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/tools/show-more/:id" element={<ToolShowMore />} />
        <Route path="/tools/edit/:id" element={<ToolEdit />} />
      </Routes>
    </>
  );
};

export default NewRoutes;
