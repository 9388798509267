import React from "react";
import { useParams } from "react-router-dom";
import { getIngredient } from "../../../services/ingredientsServices";
import { useQuery } from "react-query";
import IngredientEditForm from "./IngredientEditForm";

const IngredientEdit = () => {
  let { id } = useParams();

  const { data: ingredientData, isLoading: getIngredientLoading } = useQuery(
    ["get-ingredient", id],
    () => getIngredient(id)
  );

  if (getIngredientLoading) {
    return "...is Loading";
  }
  return <IngredientEditForm ingredient={ingredientData} id={id} />;
};

export default IngredientEdit;
