import { Button, Grid, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import "../styles/reusableComponents.css";
import { useMutation, useQueryClient } from "react-query";

const UpdateStatusModal = ({
  showStatusModal,
  setShowStatusModal,
  item,
  queryFnt,
  queryKey
}) => {
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError, isLoading } = useMutation(queryFnt, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      handleClose();
    }
  });

  console.log(queryFnt);
  const confirmStatus = () => {
    mutate(showStatusModal.id);
  };
  const declineStatus = () => {
    const errorsTemp = {};
    if (!reason) {
      errorsTemp.reason = "*This field is required";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate({
        id: showStatusModal.id,
        data: { reason: reason }
      });
    }
  };

  const handleClose = () => {
    setShowStatusModal({
      open: false,
      id: null,
      status: ""
    });
  };
  return (
    <Modal open={showStatusModal.open} onClose={handleClose}>
      <Grid
        container
        item
        xs={6}
        md={6}
        lg={5}
        direction="column"
        className="delete-modal"
      >
        {showStatusModal.status === "CONFIRMED" ? (
          <>
            <h1 className="delete-modal-title">Confirm {item}</h1>
            <h3 className="delete-modal-content">
              Do you really want to confirm this item ?
            </h3>
            <div className="delete-modal-buttons">
              <Button className="confirm-delete-button" onClick={confirmStatus}>
                Confirm
              </Button>
              <Button className="cancel-delete-button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            <h1 className="delete-modal-title">Decline {item}</h1>
            <h3
              className="delete-modal-content"
              style={{ marginBottom: "20px" }}
            >
              Do you really want to decline this item ?
            </h3>
            <div className="decline-status-reason">
              <h5>Why?</h5>
              <TextField
                id="outlined-multiline-static"
                name="reason"
                variant="outlined"
                value={reason}
                multiline
                rows={4}
                style={{ width: "100%" }}
                onChange={(e) => setReason(e.target.value)}
              />
              {errors.reason && <p className="error-msg">{errors.reason}</p>}
            </div>

            <div className="delete-modal-buttons">
              <Button className="confirm-delete-button" onClick={declineStatus}>
                Decline
              </Button>
              <Button className="cancel-delete-button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default UpdateStatusModal;
