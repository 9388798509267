import React from "react";
import { getTool } from "../../../services/toolsServices";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ToolEditForm from "./ToolEditForm";

const ToolEdit = () => {
  let { id } = useParams();

  const { data: toolData, isLoading: getToolLoading } = useQuery(
    ["get-tool", id],
    () => getTool(id)
  );

  if (getToolLoading) {
    return "...is Loading";
  }
  return <ToolEditForm tool={toolData.data.tool} id={id} />;
};

export default ToolEdit;
