import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Alert,
  Chip
} from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import useDrivePicker from "react-google-drive-picker";
import _ from "lodash";
import AddSteps from "./AddSteps";
import { updateReceipe } from "../../../services/receipesServices";

const ReceipeEditForm = ({ receipe, ingredinets, id }) => {
  const [receipeForm, setReceipeForm] = useState(receipe);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const [data, setData] = useState([
    { name: "Energy" },
    { name: "Glucide" },
    { name: "Polyols" },
    { name: "Amidon" },
    { name: "Proteines" },
    { name: "Dietary fiber" },
    { name: "Salt" },
    { name: "Vitamins" },
    { name: "Mineral salts" },
    { name: "Fat" }
  ]);
  const [openPicker, authResponse] = useDrivePicker();
  useEffect(() => {
    if (receipe) {
      let dataChecked = data.map((el) => {
        if (Object.keys(receipe.nutritionalData).includes(el.name) === true) {
          return { ...el, isChecked: true };
        } else {
          return el;
        }
      });
      setData(dataChecked);
    }
  }, [receipe]);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setReceipeForm({ ...receipeForm, [name]: value });
  };
  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let dataTemp = data.map((el) =>
      el.name === name ? { ...el, isChecked: checked } : el
    );
    dataTemp.map((el) => {
      if (
        el.isChecked === true &&
        Object.keys(receipeForm.nutritionalData).includes(el.name) === false
      ) {
        setReceipeForm((prev) => ({
          ...prev,
          nutritionalData: {
            ...prev.nutritionalData,
            [el.name]: 0
          }
        }));
      } else if (
        el.isChecked === false &&
        Object.keys(receipeForm.nutritionalData).includes(el.name) === true
      ) {
        const newItems = receipeForm.nutritionalData;
        delete newItems[el.name];
        setReceipeForm((prev) => ({
          ...prev,
          nutritionalData: newItems
        }));
      }
    });

    setData(dataTemp);
  };

  const addNutritionalDataValue = (e) => {
    setReceipeForm((prev) => ({
      ...prev,
      nutritionalData: {
        ...prev.nutritionalData,
        [e.target.name]: e.target.value
      }
    }));
  };
  //UPLOAD IMAGE IN GOOGLE DRIVE
  const SCOPE = "https://www.googleapis.com/auth/drive";
  const discoveryUrl =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  const CLIENT_ID =
    "1087047118044-nrapun4q76871bnb2mbu7s07du542mk8.apps.googleusercontent.com";
  const API_KEY = "AIzaSyARITWFDPBNjDxqpgLcct1ebCPD-Vafkmg";

  useEffect(() => {
    var script = document.createElement("script");
    script.onload = handleClientLoad;
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
  }, []);

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };

  const [token, setToken] = useState("");
  const initClient = () => {
    try {
      window.gapi.auth2
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          plugin_name: "chat",
          scope: SCOPE,
          discoveryDocs: [discoveryUrl]
        })
        .then(() => {
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn?.listen(updateSigninStatus);
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn?.get()
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  const updateSigninStatus = () => {
    setSigninStatus();
  };
  const setSigninStatus = () => {
    var user = window.gapi.auth2.getAuthInstance().currentUser.get();
    const scopes = user.getGrantedScopes()?.split(" ");
    if (scopes && !scopes.includes("https://www.googleapis.com/auth/drive")) {
      user
        .grant({
          scope: "https://www.googleapis.com/auth/drive"
        })
        .then(() => {
          console.log(user.getGrantedScopes()?.split(" "));
        });
    }
    setToken(window.gapi.auth.getToken().access_token);

    var isAuthorized = user.hasGrantedScopes();
    if (isAuthorized) {
      setToken(window.gapi.auth.getToken().access_token);
    }
  };
  const handleOpenPicker = () => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_IMAGES",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          setReceipeForm((prev) => ({
            ...prev,
            media: { ...prev.media, images: [...prev.media.images, url] }
          }));
        });
        console.log(data);
      }
    });
  };
  const handleDeleteImage = (i) => {
    let arr = receipeForm.media.images;
    setReceipeForm((prev) => ({
      ...prev,
      media: {
        ...prev.media,
        images: arr.filter((el) => receipeForm.media.images.indexOf(el) !== i)
      }
    }));
  };
  const handleOpenPickerVideos = () => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_VIDEOS",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          setReceipeForm((prev) => ({
            ...prev,
            media: { ...prev.media, videos: [...prev.media.videos, url] }
          }));
        });
        console.log(data);
      }
    });
  };
  const handleDeleteVideo = (i) => {
    let arr = receipeForm.media.videos;
    setReceipeForm((prev) => ({
      ...prev,
      media: {
        ...prev.media,
        videos: arr.filter((el) => receipeForm.media.videos.indexOf(el) !== i)
      }
    }));
  };
  const selectIngredients = (e) => {
    setReceipeForm((prev) => ({
      ...prev,
      products: { ...prev.products, products_internal: e.target.value }
    }));
  };
  const handleDeleteChip = (id) => {
    setReceipeForm((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        products_internal: receipeForm.products.products_internal.filter(
          (el) => el !== id
        )
      }
    }));
  };
  const queryClient = useQueryClient();
  const {
    mutate,
    data: updatedReceipe,
    isSuccess,
    isError
  } = useMutation(updateReceipe, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-receipes");
    }
  });
  const handleEditReceipe = () => {
    const errorsTemp = {};
    if (!receipeForm.text) {
      errorsTemp.text = "*This field is required";
    }
    if (!receipeForm.description) {
      errorsTemp.description = "*This field is required";
    }
    if (!receipeForm.type) {
      errorsTemp.type = "*This field is required";
    }
    if (!receipeForm.difficulty) {
      errorsTemp.difficulty = "*This field is required";
    }
    if (
      !receipeForm.persons ||
      isNaN(receipeForm.persons) ||
      receipeForm.persons < 0
    ) {
      errorsTemp.persons = "*This field must be a positif number";
    }
    if (!receipeForm.instructions) {
      errorsTemp.instructions = "*This field is required";
    }
    if (receipeForm.products.products_internal.length === 0) {
      errorsTemp.products_internal = "*This field is required";
    }
    if (Object.keys(receipeForm.nutritionalData).length === 0) {
      errorsTemp.nutritionalData = "*Please fill in the nutritional data";
    }
    if (receipeForm.steps.length !== 0) {
      let indexTab = [];
      receipeForm.steps.map(
        (step, index) => !step.name && indexTab.push(index)
      );
      if (indexTab.length !== 0) {
        errorsTemp["steps"] = [];
        for (let i = 0; i < receipeForm.steps.length; i++) {
          if (indexTab.includes(i) === true) {
            errorsTemp.steps[i] = "*This field is required";
          } else {
            errorsTemp.steps[i] = false;
          }
        }
      }
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      const dataToSend = _.omit(receipeForm, [
        "_id",
        "createdAt",
        "status",
        "createdBy",
        "declineReason"
      ]);
      mutate({ id: id, data: dataToSend });
    }
  };
  useEffect(() => {
    if (updatedReceipe?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updatedReceipe?.success]);
  return (
    <div>
      <div className="show-more-container">
        <div className="show-more-elements-container">
          <div className="show-more-element">
            <h5>Name</h5>
            <TextField
              id="outlined-basic"
              name="text"
              variant="outlined"
              style={{ width: "100%" }}
              value={receipeForm?.text}
              onChange={handleChange}
            />
            {errors.text && <p className="error-msg">{errors.text}</p>}
          </div>
          <div className="show-more-element">
            <h5>description</h5>
            <TextField
              id="outlined-multiline-static"
              name="description"
              variant="outlined"
              value={receipeForm?.description}
              multiline
              rows={4}
              style={{ width: "100%" }}
              onChange={handleChange}
            />
            {errors.text && <p className="error-msg">{errors.description}</p>}
          </div>
          <div className="show-more-element">
            <h5>Persons</h5>
            <TextField
              id="outlined-basic"
              name="persons"
              variant="outlined"
              style={{ width: "100%" }}
              value={receipeForm?.persons}
              onChange={handleChange}
              type="number"
            />
            {errors.persons && <p className="error-msg">{errors.persons}</p>}
          </div>
          <div className="show-more-element">
            <h5>Type</h5>
            <FormControl sx={{ m: 1, minWidth: "50%", margin: 0 }}>
              <Select
                id="demo-select-small"
                name="type"
                value={receipeForm?.type}
                onChange={handleChange}
              >
                <MenuItem value="SALTY">Salty</MenuItem>
                <MenuItem value="SWEET">Sweet</MenuItem>
                <MenuItem value="DOUGH">Dough</MenuItem>
              </Select>
            </FormControl>
            {errors.type && <p className="error-msg">{errors.type}</p>}
          </div>
          <div className="show-more-element">
            <h5>Difficulty</h5>
            <FormControl sx={{ m: 1, minWidth: "50%", margin: 0 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="difficulty"
                value={receipeForm?.difficulty}
                onChange={handleChange}
              >
                <MenuItem value="EASY">Easy</MenuItem>
                <MenuItem value="MEDIUM">Medium</MenuItem>
                <MenuItem value="HARD">Hard</MenuItem>
              </Select>
            </FormControl>
            {errors.difficulty && (
              <p className="error-msg">{errors.difficulty}</p>
            )}
          </div>
          <div className="show-more-element">
            <h5>Instructions</h5>
            <TextField
              id="outlined-multiline-static"
              name="instructions"
              variant="outlined"
              value={receipeForm?.instructions}
              multiline
              rows={5}
              style={{ width: "100%" }}
              onChange={handleChange}
            />
            {errors.instructions && (
              <p className="error-msg">{errors.instructions}</p>
            )}
          </div>
          <div className="show-more-element">
            <h5>Internal Ingredients</h5>
            <div>
              <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
                <Select
                  id="demo-select-small"
                  name="products_internal"
                  value={receipeForm?.products.products_internal}
                  onChange={selectIngredients}
                  multiple
                >
                  {ingredinets.map((el, i) => (
                    <MenuItem key={i} value={el._id}>
                      {el.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="chips-container">
                {receipeForm?.products.products_internal.map((el, i) => (
                  <Chip
                    key={i}
                    label={
                      ingredinets.find((ingredinet) => ingredinet._id === el)
                        ?.text
                    }
                    onDelete={() => handleDeleteChip(el)}
                  />
                ))}
              </div>
              {errors.products_internal && (
                <p className="error-msg">{errors.products_internal}</p>
              )}
            </div>
          </div>
          <div className="show-more-element">
            <h5>Nutritional Data</h5>
            <FormGroup style={{ flexDirection: "row" }}>
              {data.map((el, i) => (
                <div style={{ width: 150 }} key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={el?.isChecked || false}
                        name={el.name}
                        onChange={handleCheck}
                      />
                    }
                    label={el.name}
                    style={{ marginBottom: 10 }}
                  />
                  {el.isChecked && (
                    <TextField
                      id="outlined-multiline-static"
                      className="nutirtional-data-input"
                      name={el.name}
                      value={receipeForm.nutritionalData[el.name]}
                      variant="outlined"
                      style={{ width: "80%" }}
                      onChange={addNutritionalDataValue}
                      defaultValue={0}
                    />
                  )}
                </div>
              ))}
            </FormGroup>
            {errors.nutritionalData && (
              <p className="error-msg">{errors.nutritionalData}</p>
            )}
          </div>

          <div className="show-more-element">
            <h5>Images</h5>
            <div className="images-wrapper">
              {receipeForm?.media.images.map((el, i) => (
                <div key={i} className="image-container">
                  <div className="image-box">
                    <img alt="" src={el} />
                  </div>
                  <IconButton
                    className="delete-image"
                    onClick={() => handleDeleteImage(i)}
                  >
                    <CancelTwoToneIcon />
                  </IconButton>
                </div>
              ))}
              <div className="modal-add-image-container">
                <div className="inner-add-image-container">
                  <IconButton onClick={() => handleOpenPicker()}>
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </div>

                <p>Add an image</p>
              </div>
            </div>
          </div>
          <div className="show-more-element">
            <h5>Videos</h5>
            <div className="images-wrapper">
              {receipeForm?.media.videos.map((el, i) => (
                <div key={i} className="video-container">
                  <div className="video-box">
                    <video alt="" src={el} controls />
                  </div>
                  <IconButton
                    className="delete-image"
                    onClick={() => handleDeleteVideo(i)}
                  >
                    <CancelTwoToneIcon />
                  </IconButton>
                </div>
              ))}
              <div className="modal-add-video-container">
                <div className="inner-add-video-container">
                  <IconButton onClick={() => handleOpenPickerVideos()}>
                    <VideoFileIcon />
                  </IconButton>
                </div>

                <p>Add a video</p>
              </div>
            </div>
          </div>
          <AddSteps
            receipeForm={receipeForm}
            setReceipeForm={setReceipeForm}
            ingredinets={ingredinets}
            errors={errors}
          />
          <div className="show-more-button-container">
            <Button
              variant="contained"
              className="add-button"
              onClick={handleEditReceipe}
            >
              Save
            </Button>
            <Link to={`/dashboard/receipes/show-more/${receipeForm?._id}`}>
              <Button variant="contained" className="cancel-button">
                Cancel
              </Button>
            </Link>
          </div>
          {showAlert && (
            <>
              <Alert
                severity="success"
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                Receipe is updated!
              </Alert>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReceipeEditForm;
