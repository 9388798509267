import api from "../config/api";

export const logInAdmin = async (data) => {
  return await api({
    method: "post",
    url: `/api/auth/admins/signin`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get Admin account
export const adminAccount = () => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/admins/me`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
