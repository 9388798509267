import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import IngredientDefaultImage from "../../../assets/No_image_available.svg.png";
import { deleteReceipe } from "../../../services/receipesServices";
import DeleteModal from "../../../components/DeleteModal";
import { Link } from "react-router-dom";
import { convertDate } from "../../../utils/convertDate";

const ReceipeCard = ({ receipe }) => {
  const [showDeleteModal, setShowDeleteModal] = useState({
    open: false,
    id: null
  });
  return (
    <div className="card-container">
      <div className="card-image-container">
        <img
          alt=""
          src={
            receipe.media?.images && receipe.media?.images.length !== 0
              ? receipe.media?.images[0]
              : IngredientDefaultImage
          }
        />
      </div>
      <Button
        variant="contained"
        className={
          receipe.status === "CONFIRMED"
            ? "card-status green"
            : receipe.status === "DECLINED"
            ? "card-status red"
            : "card-status yellow"
        }
      >
        {receipe.status}
      </Button>
      <div className="card-infos">
        <h3>{receipe.text}</h3>
        <div className="card-description">
          <div>
            <h5>{receipe.description}</h5>
            <p className="card-date">{convertDate(receipe.createdAt)}</p>
          </div>
          <div className="card-icons">
            <Link to={`/dashboard/receipes/show-more/${receipe._id}`}>
              <IconButton>
                <InfoIcon style={{ color: "#079a91" }} />
              </IconButton>
            </Link>
            <IconButton
              onClick={() =>
                setShowDeleteModal({
                  open: true,
                  id: receipe._id
                })
              }
            >
              <DeleteIcon style={{ color: "#ef2531" }} />
            </IconButton>
          </div>
        </div>
      </div>

      {showDeleteModal.open && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          item="Receipe"
          queryFnt={deleteReceipe}
          queryKey="list-receipes"
        />
      )}
    </div>
  );
};

export default ReceipeCard;
