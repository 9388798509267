import React, { useState, useEffect } from "react";
import { Modal, Grid, Button, TextField, IconButton } from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import useDrivePicker from "react-google-drive-picker";
import { useMutation, useQueryClient } from "react-query";
import { createTool } from "../../../services/toolsServices";

const ToolModal = ({ showModal, setShowModal }) => {
  const [toolForm, setToolForm] = useState({
    name: "",
    image: ""
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setToolForm({ ...toolForm, [name]: value });
  };
  const [openPicker, authResponse] = useDrivePicker();
  const handleClose = () => {
    setShowModal(false);
  };
  //UPLOAD IMAGE IN GOOGLE DRIVE
  const SCOPE = "https://www.googleapis.com/auth/drive";
  const discoveryUrl =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  const CLIENT_ID =
    "1087047118044-nrapun4q76871bnb2mbu7s07du542mk8.apps.googleusercontent.com";
  const API_KEY = "AIzaSyARITWFDPBNjDxqpgLcct1ebCPD-Vafkmg";

  useEffect(() => {
    var script = document.createElement("script");
    script.onload = handleClientLoad;
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
  }, []);

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };

  const [token, setToken] = useState("");
  const initClient = () => {
    try {
      window.gapi.auth2
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          plugin_name: "chat",
          scope: SCOPE,
          discoveryDocs: [discoveryUrl]
        })
        .then(() => {
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn?.listen(updateSigninStatus);
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn?.get()
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  const updateSigninStatus = () => {
    setSigninStatus();
  };
  const setSigninStatus = () => {
    var user = window.gapi.auth2.getAuthInstance().currentUser.get();
    const scopes = user.getGrantedScopes()?.split(" ");
    if (scopes && !scopes.includes("https://www.googleapis.com/auth/drive")) {
      user
        .grant({
          scope: "https://www.googleapis.com/auth/drive"
        })
        .then(() => {
          console.log(user.getGrantedScopes()?.split(" "));
        });
    }
    setToken(window.gapi.auth.getToken()?.access_token);

    var isAuthorized = user.hasGrantedScopes();
    if (isAuthorized) {
      setToken(window.gapi.auth.getToken().access_token);
    }
  };
  const handleOpenPicker = () => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_IMAGES",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          setToolForm((prev) => ({
            ...prev,
            image: url
          }));
        });
        console.log(data);
      }
    });
  };
  const handleDeleteImage = () => {
    setToolForm({
      ...toolForm,
      image: ""
    });
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createTool, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-tools");
      handleClose();
    }
  });
  const handleSubmitTool = () => {
    const errorsTemp = {};
    if (!toolForm.name) {
      errorsTemp.name = "*This field is required";
    }

    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(toolForm);
    }
  };
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll", zIndex: 4 }}
    >
      <Grid
        container
        item
        xs={8}
        md={6}
        direction="column"
        className="add-ingredient-modal-wrapper"
      >
        <div className="add-tool-modal-inputs">
          <Grid
            container
            item
            xs={10}
            direction="column"
            className="modal-left-side"
          >
            <div>
              <h5>Name</h5>
              <TextField
                id="outlined-basic"
                name="name"
                variant="outlined"
                style={{ width: "80%" }}
                value={toolForm.name}
                onChange={handleChange}
              />
              {errors.name && <p className="error-msg">{errors.name}</p>}
            </div>
          </Grid>

          <Grid
            container
            item
            xs={10}
            direction="column"
            className="modal-right-side"
          >
            <div>
              <h5>Image</h5>
              <div className="images-wrapper">
                {toolForm.image ? (
                  <div className="image-container">
                    <div className="image-box">
                      <img alt="" src={toolForm.image} />
                    </div>
                    <IconButton
                      className="delete-image"
                      onClick={() => handleDeleteImage()}
                    >
                      <CancelTwoToneIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div className="modal-add-image-container">
                    <div className="inner-add-image-container">
                      <IconButton onClick={() => handleOpenPicker()}>
                        <AddPhotoAlternateIcon />
                      </IconButton>
                    </div>

                    <p>Ajouter une image</p>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </div>
        <div className="add-ingredient-modal-buttons">
          <Button
            variant="contained"
            className="add-button"
            onClick={handleSubmitTool}
          >
            Add
          </Button>
          <Button
            variant="contained"
            className="cancel-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default ToolModal;
