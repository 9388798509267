import api from "../config/api";

// get tools
export const listTools = (currentPage) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/tools/?page=${currentPage}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// delete a tool
export const deleteTool = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "delete",
    url: `/api/tools/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// create tool
export const createTool = (data) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "post",
    url: `/api/tools`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// get a tool
export const getTool = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/tools/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// update a tool
export const updateTool = ({ id, data }) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "put",
    url: `/api/tools/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};
