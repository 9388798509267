import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import HealthCheckImage from "../../assets/health-check-login.png";
import Visibility from "@mui/icons-material/Visibility";
import "../../styles/login.css";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from "@mui/material";
import { logInAdmin } from "../../services/authServices";
import { GlobalContext } from "../../providers/Global";

const Login = () => {
  const { setCurrentUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };
  const { mutate, isSuccess, data, isError, reset } = useMutation(logInAdmin);
  const onSubmit = () => {
    const errorsTemp = {};

    if (!loginForm.email) {
      errorsTemp.email = "*This field is required";
    }
    if (!loginForm.password) {
      errorsTemp.password = "*This field is required";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(loginForm);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("health_check_token", data.data.token);
      setCurrentUser(data?.data.admin);
      navigate("/dashboard/ingredients");
    }
    if (isError) {
      const errorsTemp = {};
      errorsTemp.login = "*Please enter your email and password";
      setErrors(errorsTemp);
    }
  }, [isSuccess, isError]);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-image-container">
          <img alt="" src={HealthCheckImage} />
        </div>

        <div className="login-form">
          <h2>Health Check</h2>
          <h6>Welcome to your admin space</h6>
          <TextField
            id="outlined-basic"
            name="email"
            label="Email"
            variant="outlined"
            value={loginForm.email}
            onChange={handleChange}
            error={errors.email ? true : errors.login ? true : false}
          />
          {errors.email && <p className="login-error-msg">{errors.email}</p>}
          <FormControl
            variant="outlined"
            error={errors.password ? true : errors.login ? true : false}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={loginForm.password}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={(e) => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          {errors.password && (
            <p className="login-error-msg">{errors.password}</p>
          )}
          {errors.login && (
            <p className="login-error-msg" style={{ marginTop: 10 }}>
              {errors.login}
            </p>
          )}
          <Button
            variant="contained"
            className="login-button"
            onClick={onSubmit}
          >
            Log in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
