import React from "react";
import { Modal } from "@mui/material";
import ReceipeModalForm from "./ReceipeModalForm";
import { allIngredients } from "../../../services/ingredientsServices";
import { useQuery } from "react-query";

const ReceipeModal = ({ showModal, setShowModal }) => {
  const { data: ingredinets, isLoading } = useQuery(
    "all-ingredinets",
    allIngredients
  );
  const handleClose = () => {
    setShowModal(false);
  };
  if (isLoading) {
    return "...is loading";
  }
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll", zIndex: 4 }}
    >
      <ReceipeModalForm
        ingredinets={ingredinets.data.products}
        handleClose={handleClose}
      />
    </Modal>
  );
};

export default ReceipeModal;
