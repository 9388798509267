import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import useDrivePicker from "react-google-drive-picker";

const AddSteps = ({ receipeForm, setReceipeForm, ingredinets, errors }) => {
  const addStep = () => {
    setReceipeForm((prev) => ({
      ...prev,
      steps: [
        ...prev.steps,
        {
          number: receipeForm.steps.length + 1,
          name: "",
          description: "",
          time: "",
          tools: [],
          media: { images: [], videos: [] },
          products: { products_internal: [], products_external: [] }
        }
      ]
    }));
  };
  const handleChange = (index, e) => {
    const values = [...receipeForm.steps];
    values[index][e.target.name] = e.target.value;
    setReceipeForm((prev) => ({
      ...prev,
      steps: values
    }));
  };
  const handleDeleteStep = (index) => {
    let arr = receipeForm.steps;
    setReceipeForm((prev) => ({
      ...prev,
      steps: arr.filter((el) => receipeForm.steps.indexOf(el) !== index)
    }));
  };
  useEffect(() => {
    let arr = receipeForm.steps;
    arr.forEach((el, index) => (el.number = index + 1));
    setReceipeForm((prev) => ({
      ...prev,
      steps: arr
    }));
  }, [receipeForm.steps]);
  const [openPicker, authResponse] = useDrivePicker();
  //UPLOAD IMAGE/VIDEO IN GOOGLE DRIVE
  const SCOPE = "https://www.googleapis.com/auth/drive";
  const discoveryUrl =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  const CLIENT_ID =
    "1087047118044-nrapun4q76871bnb2mbu7s07du542mk8.apps.googleusercontent.com";
  const API_KEY = "AIzaSyARITWFDPBNjDxqpgLcct1ebCPD-Vafkmg";

  useEffect(() => {
    var script = document.createElement("script");
    script.onload = handleClientLoad;
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
  }, []);

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };

  const [token, setToken] = useState("");
  const initClient = () => {
    try {
      window.gapi.auth2
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          plugin_name: "chat",
          scope: SCOPE,
          discoveryDocs: [discoveryUrl]
        })
        .then(() => {
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn?.listen(updateSigninStatus);
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn?.get()
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  const updateSigninStatus = () => {
    setSigninStatus();
  };
  const setSigninStatus = () => {
    var user = window.gapi.auth2.getAuthInstance().currentUser.get();
    const scopes = user.getGrantedScopes().split(" ");
    if (!scopes.includes("https://www.googleapis.com/auth/drive")) {
      user
        .grant({
          scope: "https://www.googleapis.com/auth/drive"
        })
        .then(() => {
          console.log(user.getGrantedScopes().split(" "));
        });
    }
    setToken(window.gapi.auth.getToken().access_token);

    var isAuthorized = user.hasGrantedScopes();
    if (isAuthorized) {
      setToken(window.gapi.auth.getToken().access_token);
    }
  };
  const handleOpenPicker = (index) => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_IMAGES",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: SCOPE,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        const values = [...receipeForm.steps];
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          values[index].media.images = [...values[index].media.images, url];
          console.log(values[index].media.images);
        });
        setReceipeForm((prev) => ({
          ...prev,
          steps: values
        }));
      }
    });
  };
  const handleDeleteImage = (index, i) => {
    const values = [...receipeForm.steps];
    let arr = values[index].media.images;
    values[index].media.images = arr.filter(
      (el) => values[index].media.images.indexOf(el) !== i
    );
    setReceipeForm((prev) => ({
      ...prev,
      steps: values
    }));
  };
  const handleOpenPickerVideos = (index) => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_VIDEOS",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: SCOPE,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        const values = [...receipeForm.steps];
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          values[index].media.videos = [...values[index].media.videos, url];
        });
        setReceipeForm((prev) => ({
          ...prev,
          steps: values
        }));
        console.log(data);
      }
    });
  };
  const handleDeleteVideo = (index, i) => {
    const values = [...receipeForm.steps];
    let arr = values[index].media.videos;
    values[index].media.videos = arr.filter(
      (el) => values[index].media.videos.indexOf(el) !== i
    );
    setReceipeForm((prev) => ({
      ...prev,
      steps: values
    }));
  };
  const selectIngredients = (index, e) => {
    const values = [...receipeForm.steps];
    values[index].products.products_internal = e.target.value;
    setReceipeForm((prev) => ({
      ...prev,
      steps: values
    }));
  };
  const handleDeleteChip = (index, id) => {
    const values = [...receipeForm.steps];
    let arr = values[index].products.products_internal;
    values[index].products.products_internal = arr.filter((el) => el !== id);
    setReceipeForm((prev) => ({
      ...prev,
      steps: values
    }));
  };
  return (
    <div className="show-more-element">
      <h5>
        Steps <span>({receipeForm.steps.length})</span>
      </h5>

      <Button
        variant="contained"
        endIcon={<PlaylistAddIcon />}
        onClick={addStep}
        className="add-step-button"
      >
        Add a step
      </Button>
      {receipeForm.steps.length !== 0 &&
        receipeForm.steps?.map((step, index) => (
          <div key={index} className="step-container">
            <h4 className="step-number">
              Step number <span>{step.number}</span>
            </h4>
            <div className="step-infos">
              <Grid container item xs={5} direction="column">
                <h5>Name</h5>
                <TextField
                  id="outlined-basic"
                  name="name"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={step.name}
                  onChange={(e) => handleChange(index, e)}
                />
                {errors.steps && errors.steps[index] !== false && (
                  <p className="error-msg">{errors.steps[index]}</p>
                )}
                <h5>Time</h5>
                <TextField
                  id="outlined-start-adornment"
                  name="time"
                  style={{ width: "100%" }}
                  value={step.time}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Min</InputAdornment>
                    )
                  }}
                  onChange={(e) => handleChange(index, e)}
                />
              </Grid>
              <Grid container item xs={6} direction="column">
                <h5>Description</h5>
                <TextField
                  id="outlined-multiline-static"
                  name="description"
                  variant="outlined"
                  value={step.description}
                  multiline
                  rows={5}
                  style={{ width: "100%" }}
                  onChange={(e) => handleChange(index, e)}
                />
              </Grid>
            </div>
            <div className="step-media">
              <div>
                <h5>Image</h5>
                <div className="images-wrapper">
                  {step.media.images.map((el, i) => (
                    <div key={i} className="image-container">
                      <div className="image-box">
                        <img alt="" src={el} />
                      </div>
                      <IconButton
                        className="delete-image"
                        onClick={() => handleDeleteImage(index, i)}
                      >
                        <CancelTwoToneIcon />
                      </IconButton>
                    </div>
                  ))}
                  <div className="modal-add-image-container">
                    <div className="inner-add-image-container">
                      <IconButton onClick={() => handleOpenPicker(index)}>
                        <AddPhotoAlternateIcon />
                      </IconButton>
                    </div>

                    <p>Add an image</p>
                  </div>
                </div>
              </div>

              <div>
                <h5>Videos</h5>
                <div className="images-wrapper">
                  {step.media.videos.map((el, i) => (
                    <div key={i} className="video-container">
                      <div className="video-box">
                        <video alt="" src={el} controls />
                      </div>
                      <IconButton
                        className="delete-image"
                        onClick={() => handleDeleteVideo(index, i)}
                      >
                        <CancelTwoToneIcon />
                      </IconButton>
                    </div>
                  ))}
                  <div className="modal-add-video-container">
                    <div className="inner-add-video-container">
                      <IconButton onClick={() => handleOpenPickerVideos(index)}>
                        <VideoFileIcon />
                      </IconButton>
                    </div>

                    <p>Add a video</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="step-products">
              <h5>Internal Ingredients</h5>
              <div>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <Select
                    id="demo-select-small"
                    name="products_internal"
                    value={step.products.products_internal}
                    onChange={(e) => selectIngredients(index, e)}
                    multiple
                  >
                    {ingredinets.map((el, i) => (
                      <MenuItem key={i} value={el._id}>
                        {el.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="chips-container">
                  {step.products.products_internal.map((el, i) => (
                    <Chip
                      key={i}
                      label={
                        ingredinets.find((ingredinet) => ingredinet._id === el)
                          ?.text
                      }
                      onDelete={() => handleDeleteChip(index, el)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteStep(index)}
              className="delete-step-button"
            >
              Delete
            </Button>
          </div>
        ))}
    </div>
  );
};

export default AddSteps;
