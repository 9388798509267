import React, { useState } from "react";
import "../../styles/ingredients.css";
import IngredientCard from "./components/IngredientCard";
import { Badge, Button, Pagination } from "@mui/material";
import SetMealIcon from "@mui/icons-material/SetMeal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import IngredientModal from "./components/IngredientModal";
import {
  allIngredients,
  ingredientsByName,
  ingredientsByStatus,
  listIngredients
} from "../../services/ingredientsServices";
import { useQuery } from "react-query";
import Filter from "../../components/Filter";

const Ingredients = () => {
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");

  const {
    data: ingredinets,
    isSuccess,
    isError,
    isLoading: isLoading_ingredinets
  } = useQuery(
    ["list-ingredinets", currentPage],
    () => listIngredients(currentPage),
    {
      keepPreviousData: true
    }
  );
  const { data: allIngredinets, isLoading: isLoading_all_ingredinets } =
    useQuery("all-ingredinets", allIngredients);
  const {
    data: ingredinetsByStatus,
    isLoading: isLoading_ingredinets_by_status,
    refetch: refetchByStatus
  } = useQuery(
    ["get-ingredient-status", status],
    () => ingredientsByStatus(status),
    {
      enabled: false
    }
  );

  const {
    data: ingredinetsByName,
    isLoading: isLoading_ingredinets_by_name,
    refetch: refetchByName
  } = useQuery(["get-ingredient-name", name], () => ingredientsByName(name), {
    enabled: false
  });

  if (isLoading_ingredinets || isLoading_all_ingredinets) {
    return "...is loading";
  }
  return (
    <div>
      <div className="page-settings">
        <div className="page-infos">
          <h5 className="page-title">Ingredients</h5>
          <Badge
            badgeContent={
              ingredinetsByName?.data.totalResults
                ? ingredinetsByName?.data.totalResults
                : ingredinetsByStatus?.data.totalResults
                ? ingredinetsByStatus?.data.totalResults
                : ingredinets?.data.totalResults
            }
            color="primary"
            max={999}
          >
            <SetMealIcon color="action" />
          </Badge>
        </div>
        <div className="settings-buttons">
          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            className="add-ingredient-button"
            onClick={() => setShowModal(true)}
          >
            Add ingredient
          </Button>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            className="filter-button"
            onClick={() => setShowFilter(true)}
          >
            Filter
          </Button>
        </div>
      </div>
      <Pagination
        style={{ marginTop: 10, padding: "0px 30px" }}
        page={currentPage}
        count={
          ingredinetsByName?.data.totalPages
            ? ingredinetsByName?.data.totalPages
            : ingredinetsByStatus?.data.totalPages
            ? ingredinetsByStatus?.data.totalPages
            : ingredinets?.data.totalPages
        }
        variant="outlined"
        shape="rounded"
        hidePrevButton
        hideNextButton
        onChange={(event, value) => setCurrentPage(value)}
      />
      <div className="cards-container">
        {ingredinetsByName?.data.products
          ? ingredinetsByName?.data.products.map((product, i) => (
              <IngredientCard key={i} ingredient={product} />
            ))
          : ingredinetsByStatus?.data.products
          ? ingredinetsByStatus?.data.products.map((product, i) => (
              <IngredientCard key={i} ingredient={product} />
            ))
          : ingredinets?.data.products.map((product, i) => (
              <IngredientCard key={i} ingredient={product} />
            ))}
      </div>
      {showModal && (
        <IngredientModal showModal={showModal} setShowModal={setShowModal} />
      )}
      {showFilter && (
        <Filter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          status={status}
          setStatus={setStatus}
          refetchByStatus={refetchByStatus}
          name={name}
          setName={setName}
          refetchByName={refetchByName}
          allElements={allIngredinets.data.products}
        />
      )}
    </div>
  );
};

export default Ingredients;
