import api from "../config/api";

// get receipes
export const listReceipes = (currentPage) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/receipes/?page=${currentPage}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// get receipe
export const getReceipe = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/receipes/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// delete a receipe
export const deleteReceipe = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "delete",
    url: `/api/receipes/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// confirm a receipe
export const confirmReceipe = (id) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "put",
    url: `/api/receipes/confirm/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// decline a receipe
export const declineReceipe = ({ id, data }) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "put",
    url: `/api/receipes/decline/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// create receipe
export const createReceipe = (data) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "post",
    url: `/api/receipes`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// update a receipe
export const updateReceipe = ({ id, data }) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "put",
    url: `/api/receipes/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// list all receipes
export const getAllReceipes = () => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/receipes`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// filter receipes by status
export const getReceipesByStatus = (status) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/receipes/?status=${status}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// filter receipes by name
export const getReceipesByName = (name) => {
  const token = localStorage.getItem("health_check_token");
  return api({
    method: "get",
    url: `/api/receipes/?query=${name}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
