import React, { useContext } from "react";
import "../styles/navbar.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../providers/Global";

const NavBar = () => {
  const navigate = useNavigate();
  const { logOut } = useContext(GlobalContext);

  return (
    <div className="navbar-container">
      <div className="navbar-left">
        <h1>Health Ckech</h1>
      </div>
      <div className="navbar-middle">
        {/* <Button variant="text" onClick={() => navigate("/dashboard/users")}>
          Users
        </Button> */}
        <Button
          variant="text"
          onClick={() => navigate("/dashboard/ingredients")}
        >
          Ingredients
        </Button>

        <Button variant="text" onClick={() => navigate("/dashboard/receipes")}>
          Receipes
        </Button>
        <Button variant="text" onClick={() => navigate("/dashboard/tools")}>
          Tools
        </Button>
      </div>
      <div className="navber-right">
        {/* <Tooltip title="Notifications">
          <IconButton>
            <Badge badgeContent={1} color="primary">
              <NotificationsIcon color="action" className="navbar-icon" />
            </Badge>
          </IconButton>
        </Tooltip> */}
        {/* <Tooltip title="Your profil">
          <IconButton>
            <AccountCircleIcon className="navbar-icon" />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Log out">
          <IconButton onClick={logOut}>
            <LogoutIcon className="navbar-icon" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default NavBar;
