import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Alert
} from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Link, useParams } from "react-router-dom";
import { updateIngredient } from "../../../services/ingredientsServices";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useDrivePicker from "react-google-drive-picker";
import _ from "lodash";

const IngredientEditForm = ({ ingredient, id }) => {
  const [ingredientForm, setIngredientForm] = useState(
    ingredient?.data.product
  );
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([
    { name: "Energy" },
    { name: "Glucide" },
    { name: "Polyols" },
    { name: "Amidon" },
    { name: "Proteines" },
    { name: "Dietary fiber" },
    { name: "Salt" },
    { name: "Vitamins" },
    { name: "Mineral salts" },
    { name: "Fat" }
  ]);
  const [openPicker, authResponse] = useDrivePicker();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIngredientForm({ ...ingredientForm, [name]: value });
  };

  useEffect(() => {
    if (ingredient?.success) {
      let dataChecked = data.map((el) => {
        if (
          Object.keys(ingredient?.data.product.nutritionalData).includes(
            el.name
          ) === true
        ) {
          return { ...el, isChecked: true };
        } else {
          return el;
        }
      });
      setData(dataChecked);
    }
  }, [ingredient?.success]);
  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let dataTemp = data.map((el) =>
      el.name === name ? { ...el, isChecked: checked } : el
    );
    dataTemp.map((el) => {
      if (
        el.isChecked === true &&
        Object.keys(ingredientForm.nutritionalData).includes(el.name) === false
      ) {
        setIngredientForm((prev) => ({
          ...prev,
          nutritionalData: {
            ...prev.nutritionalData,
            [el.name]: 0
          }
        }));
      } else if (
        el.isChecked === false &&
        Object.keys(ingredientForm.nutritionalData).includes(el.name) === true
      ) {
        const newItems = ingredientForm.nutritionalData;
        delete newItems[el.name];
        setIngredientForm((prev) => ({
          ...prev,
          nutritionalData: newItems
        }));
      }
    });

    setData(dataTemp);
  };

  const addNutritionalDataValue = (e) => {
    setIngredientForm((prev) => ({
      ...prev,
      nutritionalData: {
        ...prev.nutritionalData,
        [e.target.name]: e.target.value
      }
    }));
  };
  //UPLOAD IMAGE IN GOOGLE DRIVE
  const SCOPE = "https://www.googleapis.com/auth/drive";
  const discoveryUrl =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  const CLIENT_ID =
    "1087047118044-nrapun4q76871bnb2mbu7s07du542mk8.apps.googleusercontent.com";
  const API_KEY = "AIzaSyARITWFDPBNjDxqpgLcct1ebCPD-Vafkmg";

  useEffect(() => {
    var script = document.createElement("script");
    script.onload = handleClientLoad;
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
  }, []);

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };

  const [token, setToken] = useState("");
  const initClient = () => {
    try {
      window.gapi.auth2
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          plugin_name: "chat",
          scope: SCOPE,
          discoveryDocs: [discoveryUrl]
        })
        .then(() => {
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn?.listen(updateSigninStatus);
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn?.get()
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  const updateSigninStatus = () => {
    setSigninStatus();
  };
  const setSigninStatus = () => {
    var user = window.gapi.auth2.getAuthInstance().currentUser.get();
    const scopes = user.getGrantedScopes()?.split(" ");
    if (scopes && !scopes.includes("https://www.googleapis.com/auth/drive")) {
      user
        .grant({
          scope: "https://www.googleapis.com/auth/drive"
        })
        .then(() => {
          console.log(user.getGrantedScopes()?.split(" "));
        });
    }
    setToken(window.gapi.auth.getToken()?.access_token);

    var isAuthorized = user.hasGrantedScopes();
    if (isAuthorized) {
      setToken(window.gapi.auth.getToken().access_token);
    }
  };
  const handleOpenPicker = () => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS_IMAGES",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        data.docs?.map((el) => {
          let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
          setIngredientForm((prev) => ({
            ...prev,
            images: [...prev.images, url]
          }));
        });
        console.log(data);
      }
    });
  };
  const handleDeleteImage = (i) => {
    let arr = ingredientForm.images;
    setIngredientForm({
      ...ingredientForm,
      images: arr.filter((el) => ingredientForm.images.indexOf(el) !== i)
    });
  };
  const queryClient = useQueryClient();
  const {
    mutate,
    data: updatedIngredient,
    isSuccess,
    isError
  } = useMutation(updateIngredient, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-ingredinets");
    }
  });
  const handleEditIngredient = () => {
    const errorsTemp = {};
    if (!ingredientForm.text) {
      errorsTemp.text = "*This field is required";
    }
    if (!ingredientForm.description) {
      errorsTemp.description = "*This field is required";
    }
    if (!ingredientForm.type) {
      errorsTemp.type = "*This field is required";
    }
    if (Object.keys(ingredientForm.nutritionalData).length === 0) {
      errorsTemp.nutritionalData = "*Please fill in the nutritional data";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      const dataToSend = _.omit(ingredientForm, [
        "_id",
        "createdAt",
        "status",
        "createdBy",
        "declineReason"
      ]);
      mutate({ id: id, data: dataToSend });
    }
  };
  useEffect(() => {
    if (updatedIngredient?.success) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  }, [updatedIngredient?.success]);

  return (
    <div>
      <div className="show-more-container">
        <div className="show-more-elements-container">
          <div className="show-more-element">
            <h5>Name</h5>
            <TextField
              id="outlined-basic"
              name="text"
              variant="outlined"
              style={{ width: "50%" }}
              value={ingredientForm?.text}
              onChange={handleChange}
            />
            {errors.text && <p className="error-msg">{errors.text}</p>}
          </div>
          <div className="show-more-element">
            <h5>description</h5>
            <TextField
              id="outlined-multiline-static"
              name="description"
              variant="outlined"
              multiline
              rows={4}
              style={{ width: "50%" }}
              value={ingredientForm?.description}
              onChange={handleChange}
            />
            {errors.text && <p className="error-msg">{errors.description}</p>}
          </div>
          <div className="show-more-element">
            <h5>Nutritional Data</h5>
            <FormGroup style={{ flexDirection: "row" }}>
              {data.map((el, i) => (
                <div style={{ width: 150 }} key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={el?.isChecked || false}
                        name={el.name}
                        onChange={handleCheck}
                      />
                    }
                    label={el.name}
                    style={{ marginBottom: 10 }}
                  />
                  {el.isChecked && (
                    <TextField
                      id="outlined-multiline-static"
                      className="nutirtional-data-input"
                      name={el.name}
                      value={ingredientForm.nutritionalData[el.name]}
                      variant="outlined"
                      style={{ width: "80%" }}
                      onChange={addNutritionalDataValue}
                      defaultValue={0}
                    />
                  )}
                </div>
              ))}
            </FormGroup>
            {errors.nutritionalData && (
              <p className="error-msg">{errors.nutritionalData}</p>
            )}
          </div>
          <div className="show-more-element">
            <h5>Type</h5>
            <FormControl sx={{ m: 1, minWidth: "50%", margin: 0 }}>
              <Select
                id="demo-select-small"
                name="type"
                value={ingredientForm?.type}
                onChange={handleChange}
              >
                <MenuItem value="INT">Interieur</MenuItem>
                <MenuItem value="EXT">Exterieur</MenuItem>
                <MenuItem value="USER">User</MenuItem>
              </Select>
            </FormControl>
            {errors.type && <p className="error-msg">{errors.type}</p>}
          </div>
          <div className="show-more-element">
            <h5>Images</h5>
            <div className="images-wrapper">
              {ingredientForm?.images.map((el, i) => (
                <div key={i} className="image-container">
                  <div className="image-box">
                    <img alt="" src={el} />
                  </div>
                  <IconButton
                    className="delete-image"
                    onClick={() => handleDeleteImage(i)}
                  >
                    <CancelTwoToneIcon />
                  </IconButton>
                </div>
              ))}
              <div className="modal-add-image-container">
                <div className="inner-add-image-container">
                  <IconButton onClick={() => handleOpenPicker()}>
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </div>

                <p>Ajouter une image</p>
              </div>
            </div>
          </div>
          <div className="show-more-button-container">
            <Button
              variant="contained"
              className="add-button"
              onClick={handleEditIngredient}
            >
              Save
            </Button>
            <Link
              to={`/dashboard/ingredients/show-more/${ingredientForm?._id}`}
            >
              <Button variant="contained" className="cancel-button">
                Cancel
              </Button>
            </Link>
          </div>
          {showAlert && (
            <>
              <Alert
                severity="success"
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                Ingredient is updated!
              </Alert>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default IngredientEditForm;
